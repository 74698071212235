$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.flagIcon {
  margin-left: 0.5em;
  margin-top: -0.2em;
}

.customPopoverContent {
  padding: 15px;
}

.shiftTimes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.addProviderLink,
.splitLink {
  display: inline-block;
  font-size: 1.35rem !important;

  > span {
    margin-left: 0.5rem;
  }
  cursor: pointer;
  color: $tc_primary_blue !important;
  font-weight: normal;
  &:hover {
    color: $tc_primary_blue !important;
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.customButton {
  padding: 0.8rem 2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.providerSelect {
  width: auto;
  background: #ffffff url("../../../../../../images/cs-assets/cs-dropdown.svg")
    no-repeat;
  background-size: 15px;
  background-position: right 6px center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.labelPopup {
  color: #5b5a5e !important;
  font-size: 1.3rem !important;
  font-weight: 600;
  display: block !important;
}

.labelTimes {
  color: #5b5a5e !important;
  font-size: 1.3rem !important;
  font-weight: 600;
  display: flex;
  margin-right: 0.5em;
  line-height: 2.5em;
  gap: 12px;
  padding: 8px 0px;
  height: 35px;
  align-items: center;
}

.labelTally {
  color: #5b5a5e !important;
  font-size: 1.3rem !important;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 12px;
}

.iconClose {
  font-size: 2em !important;
  width: auto;
  height: auto;
}

.timePicker {
  .MuiOutlinedInput-root {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding-right: 0;

    &:focus {
      border-color: #ced4da;
    }
  }

  .MuiIconButton-root {
    padding: 2px;
  }

  .MuiOutlinedInput-input {
    height: auto !important;
    border: 0;
    font-size: 14px;
    padding: 5px 0 5px 6px;
    width: 82px;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0 !important;
  }

  .MuiIconButton-label {
    justify-content: center;
  }
}

.iconButton {
  padding: 6px;
  & .MuiIconButton-label {
    justify-content: center;
  }
}

.MuiPickersToolbar-toolbar {
  background-color: $tc_primary_blue !important;
}

.MuiPickersClockPointer-pointer {
  background-color: $tc_primary_blue !important;
}

.MuiPickersClock-pin {
  background-color: $tc_primary_blue !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid $tc_primary_blue !important;
}

.pickerTimeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.5em;
  height: 35px;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.select {
  :global(div[class^="Select-"]) {
    min-width: 70px;
    font-size: 12px;
    min-height: 24px;
  }
}

.toggleSwitchContainer {
  display: inline-block;
  align-items: center;

  .toggleSwitchLabel {
    margin-left: 10px;
    font-weight: normal;
    color: #5b5a5e;
  }
}

.flagIcon {
  margin-top: -0.2em;
}

.modalContainer {
  :global {
    position: relative;
    z-index: 1030;
    .modal.fade {
      opacity: 1;
      .modal-dialog {
        transform: translate(0, 0);
        -webkit-transition: translate(0, 0);
      }
    }
  }
}

// override modal styles
.modal {
  @include forceFontFamily;
  :global {
    .modal-dialog {
      opacity: 1;
      .modal-content {
        width: 100%;
        padding: 0;
        box-shadow: none !important;
        border: none !important;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12) !important;
        border: 1px solid #ddd;
        border-radius: 10px !important;
      }
      .modal-header {
        padding: 10px 10px;
        border-bottom: 0;
        background: inherit;
        font-family: inherit;
        align-items: flex-start !important;
        background-color: #f8f8f8;
        border-top: 1px solid #ddd;
        border-radius: 10px !important;
        .modal-title {
          width: 100%;
          user-select: none;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex !important;
          align-items: center !important;
          justify-content: start !important;
          font-size: 1.5rem;
          font-weight: 600;
          padding: 15px !important;
        }
        .close {
          font-size: 20px;
          margin: 1rem !important;
        }
      }
      .modal-body {
        min-width: 44rem;
        padding: 0 15px;
        overflow-y: auto;
        .dropdown-menu {
          max-height: 320px;
        }
      }
      .modal-footer {
        border-radius: 10px !important;
        border-top: 0;
        display: block;
        text-align: right;
        background-color: white;
        padding: 18px 30px;
        margin: 0;

        &:empty {
          padding: 15px 0;
          background-color: transparent;
        }

        button {
          padding: 0.8rem 2rem !important;
          border-radius: 0.5rem !important;
          font-size: 1.6rem !important;
          line-height: 1 !important;
          margin-right: 5px !important;
          font-weight: normal !important;
        }

        > button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.backdrop:global(.modal-backdrop) {
  background-color: #eef0f5;
  opacity: 0.5;
}

.titleText {
  color: #5b5a5e !important;
  display: inline-block !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-right: 0.5em !important;
}

.shiftTimes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addProviderLink,
.splitLink {
  font-size: 1.35rem !important;
  cursor: pointer;
  color: $tc_primary_blue !important;
  font-weight: normal;

  &:hover {
    color: $tc_primary_blue !important;
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.customButton {
  padding: 0.8rem 2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
  font-weight: normal !important;
  background-color: $tc_primary_blue !important;
  border-color: $tc_primary_blue !important;
}

.flagIcon {
  margin-left: 0.5em;
  margin-top: -0.2em;
}

.modalAssigmentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include forceFontFamilyCalendar;
}

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  p {
    color: $tc_primary_blue;
    cursor: pointer;
    margin: 0;
    text-align: left;
  }

  & .titleButton {
    background-color: transparent;
    border: none;
    padding: 0px;
  }
}

.modalSubtitle {
  color: #495057 !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
}

.iconButton {
  width: 22px;
  height: 22px;
  padding: 0;
  margin-left: 10px;
  background-color: #ffffff;
  color: #3080df;
  border: 1px solid #cdd9de !important;
  visibility: visible;
}
