$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.providerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
  }

  h2 {
    font-size: 1.2rem;
    color: #57565a;
    font-weight: 500;
    text-align: center;
    padding: auto;
    letter-spacing: 0.4px;
  }

  .customSelect {
    background: #f4f4f4;
    padding: 1rem;
    border-radius: 0.8rem;
    border: 1px solid #e0e0e0;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .selectAllContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .selectButton {
      font-weight: 600;
      cursor: "pointer";
    }
  }

  ul {
    list-style: none;
    padding: 0 !important;
    margin: 0;
  }

  .customOption {
    color: #57565a;
    padding: 0.8rem 1rem;
    background: white;
    margin: 10px 0;
    border-radius: 0.5em;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
  }

  .hiddenCheckbox {
    opacity: 0;
    position: absolute;
  }

  .checkboxLabel {
    cursor: pointer;
    padding: 0 !important;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #57565a;
    margin-left: 10px;
    transition: background-color 0.2s;
  }

  .hiddenCheckbox:checked + .checkboxLabel {
    background-color: $tc_primary_blue;
    border-color: $tc_primary_blue;
  }

  .checkIcon {
    color: white;
    font-size: 18px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    background-color: white;
  }

  .cancelButton,
  .applyButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancelButton {
    font-weight: 600;
    background-color: white;
    color: $tc_primary_blue;
    border: 1px solid $tc_primary_blue;
  }

  .applyButton {
    background-color: $tc_primary_blue;
    color: white;
    font-weight: 600;
  }

  .providerCellCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $tc_primary_light_gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .providerContainer > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  .providerContainer > div:first-child > div:last-child {
    cursor: pointer;
    font-size: 1.2em;
  }

  .checked {
    background-color: $tc_primary_blue;
    border-radius: 50%;
  }

  .uncheckedIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $tc_primary_light_gray;
  }

  a.active {
    background: $tc_primary_blue;
    color: white;
  }

  .selectMultiProvider {
    div div a {
      background: white;

      .active {
        background: $tc_primary_blue !important;
        color: white;
      }
    }
  }
}

@media (min-width: 768px) {
  .providerContainer {
    .customSelect {
      max-height: calc(100vh - 430px);
    }
  }
}

@media (min-width: 943px) {
  .providerContainer {
    .customSelect {
      max-height: calc(100vh - 380px);
    }
  }
}
