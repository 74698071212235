$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.tabContent {
  background-color: #fff;
  @include forceFontFamilyCalendar;

  .tabsWithSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
  }

  .tabs {
    display: flex;
  }

  .tab {
    margin-right: 10px;
    cursor: pointer;
    padding: 10px;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;

    &:hover {
      background-color: #ececec;
    }
  }

  .activeTab {
    border-bottom: 3px solid $tc_primary_blue;
  }

  .search {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #57565a;
    border-radius: 2rem;
    padding: 10px;
    width: 250px;
  }

  .search input {
    border: none;
    outline: none;
    padding: 5px;
    margin-right: 5px;
    flex-grow: 1;
  }

  .search .MuiSvgIcon-root {
    color: #57565a;
  }

  .providersTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;

    th,
    td {
      padding: 8px;
      border: 1px solid #909090;
      text-align: center;
      color: #57565a;
      background: white;
    }

    th {
      background-color: white;
    }
  }

  .textLegends {
    font-size: 1.2rem;
    color: #57565a;
    line-height: normal;
    text-align: left;
  }

  span.titleDesc {
    font-weight: 600;
  }
}
