$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.jobColumn {
  a {
    color: $tc_primary_blue !important;
    cursor: pointer;
  }
}

.providerColumn {
  width: 165px;
}

.rowClass {
  font-size: 1.2rem;

  td {
    color: #737373;
    font-weight: 400;

    &.jobColumn {
      color: #636165;
      font-weight: 600;
    }

    &.providerColumn {
      color: #636165;
      font-weight: 500;
    }
  }

  td:first-child {
    border-radius: 8px 0 0 8px;
  }

  td:last-child {
    border-radius: 0 8px 8px 0;
    border-right: 1px solid #e7e7e7;
  }
}

tr.splitShiftPanel {
  margin-top: -2em;
  border-top: none;

  td {
    background-color: white;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top: none;
  }
}

tr.additionalAssignmentPanel {
  margin-top: -2em;

  td {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  td:first-child {
    border-left: 1px solid #e7e7e7;
  }

  td:last-child {
    border-right: 1px solid #e7e7e7;
  }
}

.tableOptions {
  cursor: pointer;
}

.menu {
  padding: 5px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.menuItem {
  padding: 5px;
  cursor: pointer;
}

.menuItem:hover {
  background: #f0f0f0;
}

.popover {
  border-radius: 8px;
}

.selectedJobLink {
  padding-left: 8px;
}
