$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

@import "react-day-picker/lib/style.css";

.inputContainer {
  position: relative;
  :global {
    .form-control {
      border-radius: 6px;
      height: 28px;
      border: 1px solid $light-grey;
      font-size: 12px;
    }
    .DayPickerInput {
      width: 100%;
      &-Overlay {
        z-index: 10000;
      }
    }
    .DayPicker {
      font-size: 12px;
      line-height: 1;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
        sans-serif;
      .DayPicker-Day {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.selectMenu {
  max-height: 400px;
  background-color: $baby-grey;
  border: 1px solid $light-grey;
  border-radius: 6px;
  padding: 5px;
  overflow-y: auto;
  .selectItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:global(.dropdown-item) {
      font-size: 12px;
      height: 28px;
    }
  }
  .selectItemDisabled:global(.dropdown-item) {
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
  }
}

.selectInline {
  position: relative;
  .selectItem {
    &:global(.dropdown-item) {
      height: 40px;
      color: $grey;
      font-size: 14px;
      background-color: $white;
      border: 1px solid $light-grey;
      border-radius: 4px;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      .selectItemText {
        min-width: 0;
      }
      .selectCheckIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        border: 1px solid #cdd9de;
        border-radius: 50%;
        font-size: 8px;
        line-height: 1;
        flex-shrink: 0;
        margin-left: 1rem;
      }
    }
    &:global(.dropdown-item.active) {
      color: $grey;
      .selectCheckIcon {
        color: $white;
        background-color: $green;
        border-color: $green;
      }
    }
  }
  .selectItemDisabled:global(.dropdown-item) {
    background-color: $bg-grey;
  }
}

.selectFunctions {
  margin-top: 10px;
  padding: 0 5px 10px;
}

.selectToggle {
  display: flex;
  align-items: center;
  min-height: 28px;
  min-width: 120px;
  border: 1px solid $light-grey;
  border-radius: 6px;
  background-color: $baby-grey;
  padding: 0.4rem 0.75rem;
  font-size: 12px;
  line-height: 1;
  .selectedOptions {
    flex-wrap: wrap;
    & > div {
      flex-shrink: 0;
    }
  }
  .clearSelect {
    font-size: 9px;
    border-right: 1px solid #ccc;
    margin-right: 6px;
    padding: 4px 6px 4px 4px;
    cursor: pointer;
  }
  i {
    font-size: 8px;
    color: #99a4a5;
  }
}

.selectSeparator {
  margin-left: 5px;
  margin-right: 5px;
  color: #ccc;
}

.selectForceLeft {
  left: 0 !important;
}

.selectSearchable {
  .selectToggle {
    background-color: $white;
  }
  .selectSearchInput {
    height: 18px;
    color: inherit;
    background: 0px center;
    opacity: 1;
    width: 100%;
    grid-area: 1 / 2 / auto / auto;
    font: inherit;
    min-width: 2px;
    border: 0px;
    margin: 0px;
    outline: 0px;
    padding: 0px;
  }
  .pullSearchInputRight {
    order: 10;
  }
}

.timePickers {
  display: inline-block;
  margin-right: auto; /* This will push the time pickers to the left */
}

.spanPickers {
  padding: 0 10px;
}
