$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.modalContainer {
  :global {
    .modal.fade {
      opacity: 1;
      .modal-dialog {
        transform: translate(0, 0);
        -webkit-transition: translate(0, 0);
      }
    }
  }
}

.modal {
  @include forceFontFamily;
  :global {
    .modal-dialog {
      .modal-content {
        @include forceFontFamilyCalendar;
        padding: 0 15px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.089221);
        border: 1px solid #e4e7ea;
      }
      .modal-header {
        padding: 30px 15px;
        border-bottom: 0;
        background: inherit;
        font-family: inherit;
        align-items: center;
        .modal-title {
          width: 100%;
          font-size: 22px;
          line-height: 27px;
          user-select: none;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .close {
          font-size: 20px;
        }
      }
      .modal-body {
        padding: 15px;
        max-height: 600px;
        overflow-y: auto;
        // Limit height of dropdown(eg: Select) in a Modal
        .dropdown-menu {
          max-height: 320px;
        }
      }
      .modal-footer {
        border-top: 0;
        display: block;
        text-align: right;
        background-color: #fafafa;
        margin: 0 -15px;
        padding: 18px 30px;
        &:empty {
          padding: 15px 0;
          background-color: transparent;
        }
        .btn {
          margin: 0.25em;
          text-transform: uppercase;
          min-width: 110px;
        }
      }
    }
  }
}

.backdrop:global(.modal-backdrop) {
  background-color: #eef0f5;
  opacity: 0.5;
}

.contained {
  background-color: $tc_primary_blue !important;
  color: white !important;
  text-transform: capitalize !important;
}

.outlined {
  color: $tc_primary_blue !important;
  border: 2px solid $tc_primary_blue !important;
  background-color: white !important;
  text-transform: capitalize !important;
}
