$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.tabContent {
  background-color: #fff;

  .tabs {
    display: flex;
    border-bottom: 1px solid $tc_primary_blue;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 14px;

    &:hover {
      background-color: #f7f7f7;
    }
  }

  .activeTab {
    border-bottom: 3px solid $tc_primary_blue;
    font-weight: bold;
  }

  .disabledTab {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 15px;
    margin-top: 10px;

    button {
      width: 80px;
      height: 32px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
      transition: background-color 0.2s ease, color 0.2s ease,
        border-color 0.2s ease;

      &.cancelSettings {
        background-color: white;
        color: #18618e;
        border: 1px solid #18618e;

        &:disabled {
          border-color: #cbcbcc;
        }
      }

      &.saveSettings {
        background-color: #18618e;
        color: white;
        border: none;

        &:disabled {
          background-color: #cbcbcc;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        color: #5b5a5e;
      }
    }
  }
}

.content {
  padding: 15px;
}

.settingsContent {
  h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .section {
    margin-bottom: 30px;
  }

  .optionsGridTwoColumns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
  }

  .optionsColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .optionLabel {
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 10px;
    font-weight: normal;

    .checkbox {
      margin: 0;
    }
  }
  .subtitle {
    font-size: 1rem;
    font-style: italic;
    margin: 1rem 0;
  }
}

.timezoneContent {
  padding: 15px;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .timezoneSetting {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: self-end;
  }

  .optionLabel {
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 10px;
    font-weight: normal;

    .checkbox {
      margin: 0;
    }
  }
}
