$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

@import "react-day-picker/lib/style.css";

.inputContainer {
  position: relative;

  :global {
    .form-control {
      border-radius: 6px;
      height: 28px;
      border: 1px solid $light-grey;
      font-size: 12px;
      outline-color: $light-grey;

      &focus {
        border-color: $light-grey;
      }
    }

    .icono-svg {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
    }

    .DayPickerInput {
      width: 100%;

      &-Overlay {
        z-index: 10000;
      }
    }

    .DayPicker {
      font-size: 12px;
      line-height: 1;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
        sans-serif;

      .DayPicker-Day {
        width: 26px;
        height: 26px;
      }
    }

    .DayPicker-Day--today {
      background-color: white !important;
      color: $tc_primary_blue !important;
      border-radius: 2rem;
      font-weight: 700;
    }

    .DayPicker-TodayButton {
      border: none;
      background-image: none;
      box-shadow: none;
      background-color: $tc_primary_blue !important;
      color: white;
      font-size: 1.1em;
      font-weight: 500;
      cursor: pointer;
      display: block;
      margin: 0 auto;
      // margin-bottom: 1em;
      width: 85%;
      padding: 0.6em 2em;
    }

    input.datetimeCalendar {
      border: 1px solid $tc_primary_blue !important;
      color: $tc_primary_blue !important;
      padding: 1.8573rem 0.5rem 1.8573rem 1rem;
      outline-color: $tc_primary_blue !important;
      &focus-visible {
        border-color: $tc_primary_blue !important;
      }
    }
  }

  .datetimeCalendar {
    padding-left: 30px;
  }

  .iconCalendar {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $tc_primary_blue;
    pointer-events: none;
  }

  .customInputCalendar {
    display: inline-block;
  }
}
