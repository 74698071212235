$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$job-row-height: 80px;

:global(.shifts-container.printView) {
  .jobRow {
    height: auto;
    min-height: $job-row-height;
  }
}

.jobRow {
  height: $job-row-height;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid #a5a5a5;
  }
  &:hover {
    .eventInfo {
      background-color: #e4f6fe;
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
      -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
    }
    .chatIcon {
      display: block;
    }
  }
}

.jobRowLodaer {
  height: $job-row-height;
  border-bottom: 1px solid #a5a5a5;
  opacity: 0.5;
}

.eventInfo {
  width: 100%;
  display: flex;
  padding: 1rem;
  background-color: $white;
  display: flex;
  &.isCurrent {
    color: black;
  }

  &.isPast,
  &.isFuture {
    color: #a5a5a5 !important;
  }
  .currentEvent {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    .dateRange {
      line-height: 10px;
    }
    .dateShortName {
      font-size: 10px;
    }
  }
  .protocolContainer {
    display: flex;
    margin-left: 2rem;
    flex: 1;
    .protocolContent {
      flex: 1;
      padding: 0.4rem 0;
      .protocolText {
        color: #979797;
        cursor: pointer;
      }
    }
    .nextAndPassedEvents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-shrink: 0;
      color: #f22f29;
      margin-left: 2rem;
      border: 1px solid #f22f29;
      border-radius: 6px;
      padding: 8px 12px;
      background: rgba(255, 141, 135, 0.2);
    }
  }
}

.hoverUnderline {
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }
}

.hoverLink {
  composes: hoverUnderline;
  &:hover {
    color: #058cff;
  }
}

:global(.shifts-container.printView) {
  .eventInfo .currentEvent {
    height: 60px;
  }
}
