$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.CheckboxGroupContainer {
  position: relative;
}

.CheckboxesList {
  display: flex;
  flex-direction: column;
  .checkboxContainer:not(:last-child) {
    margin-bottom: 1em;
  }
}

.checkboxContainer {
  position: relative;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  align-self: flex-start;
}

.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 1em;
  width: 100%;
  height: 100%;
  z-index: 200;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:checked + .checkboxState {
    .checkboxIcon {
      border-color: $green;
      background-color: $green;
      color: $white;
      i {
        visibility: visible;
      }
    }
    .switchToggle {
      background: $green;
      &:before {
        left: 30px;
      }
    }
  }
}

.checkboxState {
  display: flex;
  align-items: center;
}

.checkboxIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  border: 1px solid #cdd9de;
  border-radius: 2px;
  font-size: 8px;
  line-height: 1;
  i {
    visibility: hidden;
  }
}

.switchToggle {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
}

label.checkboxLabel {
  position: initial;
  display: inline-block;
  font-weight: 400;
  margin: 0;
  margin-bottom: 0;
  text-indent: 1em;
  font-size: 12px;
  min-width: calc(1em + 2px);
}
