$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$dots-loader-color: #888;

.dotsLoader {
  // Generic properties used for any CSS animation
  display: inline-block;
  position: relative;
  vertical-align: middle;
  div {
    position: absolute;
    &:before,
    &:after {
      content: "";
      position: absolute;
    }
  }
  // Properties for this particular animation
  // Size and height
  & {
    width: 48px;
    height: 16px;
    div {
      left: 21px;
      top: 5px;
      width: 6px;
      height: 6px;
      background-color: $dots-loader-color;
      border-radius: 5px;
      animation: growDot 1s linear 0.15s infinite;
      &:before {
        left: -15px;
        top: 0;
        width: 6px;
        height: 6px;
        background-color: $dots-loader-color;
        border-radius: 5px;
        animation: growDot 1s linear 0s infinite;
      }
      &:after {
        left: 15px;
        top: 0;
        width: 6px;
        height: 6px;
        background-color: $dots-loader-color;
        border-radius: 5px;
        animation: growDot 1s linear 0.3s infinite;
      }
    }
  }
}

.waveLoader,
.glowLoader {
  width: 100%;
  > div {
    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;
  }
}

$wave-mask-image: linear-gradient(
  130deg,
  #000 55%,
  rgba(0, 0, 0, 0.8) 75%,
  #000 95%
);

.waveLoader {
  -webkit-mask-image: $wave-mask-image;
  mask-image: $wave-mask-image;
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: loaderWave 2s linear infinite;
}

.glowLoader {
  animation: loaderGlow 2s ease-in-out infinite;
}

@keyframes growDot {
  0% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px $dots-loader-color;
  }
  25% {
    opacity: 1;
    box-shadow: 0px 0px 0px 4px $dots-loader-color;
  }
  50% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px $dots-loader-color;
  }
  100% {
    opacity: 0.6;
    box-shadow: 0px 0px 0px 0px $dots-loader-color;
  }
}

@keyframes loaderWave {
  100% {
    mask-position: -200% 0%;
  }
}

@keyframes loaderGlow {
  50% {
    opacity: 0.2;
  }
}
