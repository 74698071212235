$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.block {
  background-color: $white;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  user-select: none;
  z-index: 0;
}

.blockBuffer {
  border-style: dashed;
  background-color: #e8e9ec;
  opacity: 0.6;
  &:hover {
    border-color: #aaa;
  }
}

.blockNormal {
  display: flex;
  flex-direction: column;
  padding: 0.5em 0.8em;
  overflow: hidden;
  .blockHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    .startDate {
      cursor: pointer;
    }
    i {
      position: absolute;
      top: 2px;
      right: 0;
      display: none;
      color: #a2b0bc;
      font-size: 80%;
      z-index: 1000;
    }
  }
  .blockBody {
    flex-grow: 1;
    z-index: 1;
  }
  .blockFooter {
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    .endDate {
      cursor: pointer;
    }
  }
  .blockDaysCount {
    color: #eee;
    font-family: Arial;
    font-weight: bold;
    font-size: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  &:hover {
    border-color: #3080df;
    .blockHeader i {
      display: block;
    }
  }
}

.dateUpdateForm {
  padding: 20px;
  height: 100px;
}
