$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.assignmentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #f3f6f9;
  border: 1px solid #f3f6f9;
  border-radius: 4px;
  padding: 2px !important;
  margin: 0 !important;
}

.disabledCellJob {
  background-color: $soft_silver !important;
  border: 1px solid $soft_silver !important;
  cursor: not-allowed !important;
  border-radius: 4px !important;
  color: #a5a5a5;
}

.highlightedAssignment {
  background-color: #f3f6f9;
  border: 1px solid $slate_grey;
  border-radius: 4px;
}

.highlightedAssignmentDraft {
  background-color: white !important;
  border: 1px solid #dadada !important;

  button {
    @include transparentBorder;
    background-color: white !important;
  }
}

.fadedAssignmentWrapper {
  background-color: #f8feff !important;
  opacity: 0.5;
  @include transparentBorder;
  border-radius: 4px;

  button {
    @include transparentBorder;
    background-color: #f8feff !important;
  }
}

.fadedDraftAssignmentWrapper {
  background-color: white !important;
  opacity: 0.5;
  border-radius: 4px;
  border: 1px solid $slate_grey !important;

  button {
    @include transparentBorder;

    background-color: white !important;
  }
}

.assignmentElementWrapper {
  position: relative;
  width: 100%;
  gap: 2px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #565559;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid transparent;
  background: none;
  padding: 2px 8px;
  margin: 0;
  text-align: left;
}

.assignmentElementWrapperFaded {
  opacity: 0.5;
}

.multipleDisabledCellJob {
  color: #a5a5a5;
}

.activeCellJob {
  background-color: #f3f6f9 !important;
  border: 1px solid #f3f6f9 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.textContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.understaffedCounter {
  position: absolute;
  top: 5px;
  right: 5px;
  color: $dark-red;
  line-height: 1.2rem;
  background-color: #dc354533;
  border-radius: 100px;
  padding: 3px 8px;
}

.filteredText {
  font-weight: 400;
  color: #a5a5a5;
}

.jobDateTime {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: normal;
  color: #565559;
  text-wrap: nowrap;
}
