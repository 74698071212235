// Ref: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
$font-size-root: 12px;
$primary: $blue;
$link-decoration: none;
$link-hover-decoration: none;
$form-feedback-icon-invalid: none;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1560px,
);
