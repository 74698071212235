$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

:global(.react-button).button {
  // border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  padding: 0 16px;
  line-height: 38px;
  text-align: center;
  min-width: 100px;
}

:global {
  .react-button {
    &.btn-shape-round {
      border-radius: 4px;
    }

    &.btn-shape-pill {
      border-radius: 30px;
    }

    &.btn-shape-circle {
      border-radius: 50%;
      padding: 0.5em;
      min-width: auto;
      line-height: 1;
    }

    &.btn-sm {
      padding: 0 10px;
      font-weight: normal;
      line-height: 26px;
      min-width: 75px;
    }

    &.btn-xs {
      padding: 0 10px;
      font-weight: normal;
      line-height: 18px;
      min-width: 75px;
    }

    &.btn-primary {
      background-color: $blue;
      &:hover {
        border-color: lighten($blue, 0.2);
      }
    }

    &.btn-outline-primary {
      color: $light-blue;
      background-color: $white;
      border-color: $light-blue;
    }

    &.btn-secondary,
    &.btn-secondary.dropdown-toggle {
      background-color: $dark-grey;
      border-color: lighten($dark-grey, 0.2);
    }

    &.btn-outline-secondary {
      color: #333;
      background-color: $white;
      border-color: #ccc;
      &:hover {
        color: #333;
        background-color: $white;
        border-color: lighten($dark-grey, 0.2);
      }
    }

    &.btn-outline-shortcut {
      color: #3e4c51;
      background-color: $white;
      border: 1px solid #d8dce3;
      height: 34px;
      &:hover {
        color: #333;
        background-color: $white;
        border-color: lighten($dark-grey, 0.2);
      }
    }

    &.btn-light {
      color: $grey;
      background-color: $baby-grey;
      &:hover {
        border-color: lighten($baby-grey, 0.2);
      }
    }

    &.btn-info {
      color: $white;
      background-color: $baby-blue;
      &:hover {
        border-color: lighten($baby-blue, 0.2);
      }
    }
  }
}
