$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.timePickerInput {
  position: relative;
}

.dropdownContainer {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  font-size: 12px;
  :global {
    .dropdown-menu.show {
      border: none;
      box-shadow: none;
      padding: 0;
    }
    .react-timekeeper {
      .react-timekeeper__tb-meridiem {
        font-size: 16px;
        padding-bottom: 0;
      }
      .react-timekeeper__meridiem-toggle {
        border-radius: 100px;
      }
    }
  }
  .clockIcon {
    cursor: pointer;
    color: $grey;
  }
}

.timePickerInIE {
  :global {
    .react-timekeeper {
      background: $white;
      box-shadow: 0 3px 11px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
      &__top-bar {
        background: $white;
      }
      &__tb-hour,
      &__tb-colon,
      &__tb-minute,
      &__tb-meridiem,
      &__dropdown-number {
        color: #8c8c8c;
      }
      &__tb-hour--active {
        color: #8eddfd;
      }
      &__time-dropdown {
        border: 1px solid #f4f4f4;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
      &__clock-wrapper {
        background: #f4f4f4;
      }
      &__clock {
        background: $white;
      }
      &__clock-hours span {
        color: #999999;
        font-size: 16px;
      }
      &__clock-hand {
        stroke: #bceaff;
      }
      &__hand-circle-center {
        fill: #bceaff;
      }
      &__hand-circle-outer {
        fill: #e6f7ff;
      }
      &__hand-intermediate-circle {
        fill: #ade2fb;
      }
      &__meridiem-toggle {
        color: #898989;
      }
      &__meridiem--active {
        background: #e1eff6;
      }
    }
  }
}
