$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$dropdown-color-field-size: 30px;
$dropdown-color-field-margin: 2px;
$dropdown-color-field-count-per-row: 8;

.colorField {
  width: 40px;
  height: 28px;
  border-radius: 4px;
}

.colorFieldClickable {
  cursor: pointer;
}

.colorPicker:global(.dropdown-menu) {
  border: 1px solid #cdd9de;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 4px 8px;
  .colorPickerContent {
    display: flex;
    flex-shrink: 1 0 auto;
    overflow: hidden;
    width: ($dropdown-color-field-size + $dropdown-color-field-margin * 2) *
      $dropdown-color-field-count-per-row;
  }
  .gridColorPicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
  }
  .colorField {
    width: $dropdown-color-field-size;
    height: $dropdown-color-field-size;
    margin: $dropdown-color-field-margin;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .customColorField {
    flex: 1;
    cursor: pointer;
    line-height: 1;
  }
  .customColorPicker {
    flex-shrink: 0;
    margin-left: 1.5rem;
  }
}
