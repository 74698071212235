$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.tabs-default {
  border-bottom: 0;
  :global {
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark-grey !important;
      font-weight: bold;
      font-size: 16px;
      border: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      &.active {
        background: $dark-grey;
      }
    }
  }
}

.tabs-underline {
  display: flex;
  padding-bottom: 10px;
  :global {
    .nav-item {
      font-weight: 700;
      min-width: 100px;
      font-size: 16px;
      text-align: center;
      border-radius: 4px;
      color: $baby-blue;
      position: relative;
      padding: 0.2em 1em;
      &:before {
        content: " ";
        position: absolute;
        height: 2px;
        width: 0;
        background-color: $baby-blue;
        bottom: -2px;
        left: 0;
      }
      &.nav-link {
        border: 0;
        &:hover,
        &:focus {
          color: darken($baby-blue, 0.5);
          border: 0;
        }
        &.active {
          color: $baby-blue;
          &:before {
            width: 100%;
            transition: width 0.3s ease-out;
          }
        }
      }
    }
  }
}

.tabs-underline-blue {
  display: flex;
  padding-bottom: 10px;
  :global {
    .nav-item {
      font-weight: 700;
      min-width: 100px;
      font-size: 16px;
      text-align: center;
      border-radius: 4px;
      color: $tc_primary_blue;
      position: relative;
      padding: 0.2em 1em;
      &:before {
        content: " ";
        position: absolute;
        height: 2px;
        width: 0;
        background-color: $tc_primary_blue;
        bottom: -2px;
        left: 0;
      }
      &.nav-link {
        border: 0;
        &:hover,
        &:focus {
          color: darken($tc_primary_blue, 0.5);
          border: 0;
        }
        &.active {
          color: $tc_primary_blue;
          &:before {
            width: 100%;
            transition: width 0.3s ease-out;
          }
        }
      }
    }
  }
}

.tabs-button {
  background: rgba($dark-grey, 0.15);
  display: flex;
  margin-bottom: 15px;
  border-radius: 100px;
  :global {
    .nav-link {
      padding: 0.5rem 0;
    }
    .nav-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey;
      &:hover,
      &:focus {
        color: $dark-grey;
        border-color: transparent;
      }
      &.active {
        color: $white;
        background: $blue;
        font-weight: bold;
        border-color: transparent;
        border-radius: 100px;
      }
    }
  }
}

.tabs-navigation {
  display: flex;
  padding-bottom: 2px;
  border-bottom: 1px solid $light-grey !important;
  :global {
    .nav-item {
      min-width: 100px;
      font-size: 16px;
      text-align: center;
      border-radius: 4px;
      color: $dark-grey;
      position: relative;
      padding: 0.2em 1em;
      &:before {
        content: " ";
        position: absolute;
        height: 2px;
        width: 0;
        background-color: $baby-blue;
        bottom: -2px;
        left: 0;
      }
      &.nav-link {
        border: 0;
        &:hover,
        &:focus {
          color: darken($baby-blue, 0.5);
          border: 0;
        }
        &.active {
          color: $baby-blue;
          font-weight: 700;
          &:before {
            width: 100%;
            transition: width 0.3s ease-out;
          }
        }
      }
    }
  }
}
