$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$placements: top, left, bottom, right;

@mixin tooltipArrowColor($color) {
  @each $placement in $placements {
    &[x-placement^="#{$placement}"] {
      :global(.arrow:before) {
        border-#{$placement}-color: $color;
      }
    }
  }
}

.tooltipContainer {
  &:global(.show) {
    opacity: 1;
    .tooltip {
      opacity: 1;
    }
  }
}

.tooltip {
  &:global(.show) {
    opacity: 1;
  }
  :global(.tooltip-inner) {
    text-align: left;
    padding: 8px 10px;
    font-size: 1rem;
  }
}

.bg-white {
  filter: drop-shadow(0px 1px 4px #cecedc);
  @include tooltipArrowColor(#fff);

  &[x-placement="bottom-start"] {
    padding: 6.4px 0;
    :global(.arrow) {
      top: 0;
      &:before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
      }
    }
  }

  :global(.tooltip-inner) {
    color: rgba($dark-grey, 0.8);
    background-color: $white;
  }
}
