$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.additionalAssignments {
  padding: 9px 2px;
  :first-child {
    cursor: pointer;
  }
}

.arrowIcon {
  margin-left: 5px;
  color: $tc_primary_light_gray;
}

.iconButton {
  &:global(.MuiIconButton-root) {
    background-color: #ffffff;
    visibility: visible;
    color: #3080df;
    border: 1px solid #cdd9de;
    width: 2px;
    height: 2px;
    margin-left: 10px;
    margin-top: -4px;
  }
}

.addProviderLink {
  display: inline-block;
  font-size: 1rem !important;
  margin-bottom: 3rem;
  margin-left: 0.5em;

  > span {
    margin-left: 0.5rem;
  }

  cursor: pointer;
  color: $tc_primary_blue !important;
  font-weight: normal;

  &:hover {
    color: $tc_primary_blue !important;
  }
}

tr.additionalAssignmentPanel {
  margin-top: -2em;

  td {
    background-color: white;
    border-top: none;
    border-left: none;
    border-right: none;
    vertical-align: top;
  }

  td:first-child {
    border-left: 1px solid #e7e7e7;
  }

  td:last-child {
    border-right: 1px solid #e7e7e7;
  }
}

.headers {
  font-size: 1.3rem;
  font-weight: 600;
  color: #5b5a5e !important;
  padding-bottom: 10px;
}

.shiftTimes {
  height: 25px;
  margin-bottom: 20px;
  color: #737373;
  font-weight: 400;
}

.customButton {
  padding: 0.8rem 2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.saveButton {
  background-color: $tc_primary_blue !important;
  border-color: $tc_primary_blue !important;
}

.cancelButton {
  border-color: $tc_primary_blue !important;
  color: $tc_primary_blue !important;
  background-color: white !important;
}
