$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.quickModalContainer {
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 1px solid #e0e0e0;
  max-height: calc(70vh - 220px);
  overflow-y: auto;

  & .unorderedListContainer {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .customOption {
      color: #57565a;
      background: white;
      border-radius: 0.5em;
      cursor: pointer;

      & .optionInnerContainer {
        padding: 0.8rem 1rem;
        border: 1px solid #e0e0e0;
        border-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
      }

      & .checkboxContainer {
        display: flex;
        align-items: center;

        & .uncheckedIcon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid $tc_primary_light_gray;
        }

        & .checkIcon {
          color: white;
          font-size: 18px;
        }
      }

      & .checkedContainer {
        background-color: $tc_primary_blue;
        border-radius: 50%;
      }
    }
  }
}

.selectMultiJob {
  div div a {
    background: white;

    .active {
      background: $tc_primary_blue;
      color: white;
    }
  }
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.clearButton {
  background-color: $light-grey;
  border-radius: 16px;
  width: auto;
}

.closeRightModalIcon {
  font-size: 28px !important;
  cursor: pointer;
  z-index: 101;
  color: #9baab6;
}

.quickModalTitle {
  font-size: 1.5rem;
  color: #656568;
  font-weight: 400;
}

.quickModalDescription {
  font-size: 1.4rem;
  color: #656568;
  font-weight: 400;
  margin-top: 1rem;
}

.quickModalBodyContent {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.hiddenCheckbox:checked + .checkboxLabel {
  background-color: $tc_primary_blue;
  border-color: $tc_primary_blue;
}
