$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.jobsTable {
  display: block;
  width: 100%;
  max-height: 550px;
  border-collapse: separate !important;
  border-spacing: 0 1rem;
  font-weight: normal;
  table-layout: fixed;
  overflow-y: auto;
  padding: 0;
  padding-left: 2rem;

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 1px 3px 17px -4px rgba(194, 194, 194, 1);

    & tr {
      background-color: #ffffff;

      & th {
        padding: 1rem 0;
      }
    }
  }

  tbody {
    overflow-y: auto;
    overflow-x: visible;
  }

  td {
    background-color: #f8f8f8;
    border: none;
    border-bottom: 10px solid transparent;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    text-align: left;
  }

  .shifttimeContainer {
    height: 40px;
  }

  .shiftMultiTime {
    padding: 6px 0 7px;
  }

  .shiftTime {
    margin-top: 45px;
  }

  .arrowIcon {
    margin-left: 5px;
    color: $tc_primary_light_gray;
  }
}

.addProviderLink,
.splitLink {
  display: inline-block;
  font-size: 1rem !important;
  margin-bottom: 3rem;
  margin-left: 0.5em;

  > span {
    margin-left: 0.5rem;
  }

  cursor: pointer;
  color: $tc_primary_blue !important;
  font-weight: normal;

  &:hover {
    color: $tc_primary_blue !important;
  }

  td {
    border: none;
    border-left: none;
    border-right: none;
  }
}

.iconButton {
  &:global(.MuiIconButton-root) {
    width: 19;
    height: 19;
    margin-left: 10;
    background-color: #ffffff;
    visibility: visible;
    padding: 2px;
    margin-top: 8px;
    color: #3080df;
    border: 1px solid #cdd9de;
  }
}

.divSelectProviderMultiple {
  padding: 9px 2px;
}
