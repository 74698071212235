$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.navColor {
  background-color: $tc_primary_blue !important;
  @include forceFontFamilyCalendar;
}

.navMainList {
  list-style: none;
  margin: 0 !important;
  background: $tc_primary_blue !important;
  font-size: 1.1em !important;
  font-weight: 300;
}

.navMain * {
  font-size: 1em !important;
  background-color: $tc_primary_blue !important;

  font-weight: 300 !important;
  list-style: none !important;
  margin: 0 !important;
  height: 52px;

  ul {
    background: #0f5784 !important;
  }

  li {
    &:hover {
      background: #0f5784 !important;
      color: white;
    }
  }

  a {
    padding: 1em !important;
    &:hover {
      background: #0f5784 !important;
      color: white;
    }
  }
}

#tigerconnect_avatar {
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin-top: -50px;
  margin-left: 10px;
  margin-right: 10px;
}

.logout {
  cursor: pointer;
}

.changeRole {
  outline: none;
  border: none;
  background-color: white;
  color: $tc_primary_blue;
  cursor: pointer;
}

.helpWrapper {
  border-left: solid 1px #4c89af !important;
}
