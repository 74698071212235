$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.paginationControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 5px;

  button {
    margin: 0 8px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #dadada;
    border-radius: 5px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  span {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  height: 50px;

  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .filtersContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 45px;
  }

  .searchInput {
    width: 350px;
    font-size: 12px;
    margin-left: 10px;
  }

  button {
    padding: 0.8rem 1.5rem !important;
    border-radius: 5px;
    font-size: 12px !important;
    color: white;
    line-height: 1 !important;
    font-weight: 600 !important;
    background-color: #18618e;
    border: 1px solid #18618e;
  }

  button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  h2.clinicTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.noDataMessage {
  padding: 10px;
  background-color: #f8f8f8;
  text-align: center;
  word-wrap: break-word;
  font-weight: 500;
  min-width: 100px;
  color: #737373;
  border: 0.5px solid #dadada;
  border-radius: 10px;
}
