$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.calendarControls {
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;

  .monthPickerContainer {
    position: relative;
    display: flex;
    align-items: center;

    .monthYearPicker {
      text-transform: uppercase;
      padding: 8px 12px;
      border-radius: 4px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: white;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: $tc_primary_blue;
      }
    }

    .monthPickerIcon {
      position: absolute;
      right: 10px;
      cursor: pointer;
      color: $tc_primary_blue;
    }
  }
}

.topRow {
  font-size: 1.5rem;
  text-align: center;
  padding: 1.2rem 0;
  border-bottom: 1px solid #d8dce3;
  justify-content: center;
  justify-items: center;
  font-stretch: expanded;

  .groupName {
    color: #57565a;
    font-stretch: expanded;
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    justify-items: center;

    > strong {
      font-weight: 600;
      font-stretch: expanded;
      margin-left: 5px;
    }
  }
  .legacyView {
    color: #57565a;
    font-stretch: expanded;
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 6px;
  }
}

.secondRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 8px;

  .secondRowLeft {
    width: 116px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
  }

  .menuCTA {
    background-color: $tc_primary_blue;
    padding: 1.2077rem;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;

    &:hover {
      background-color: darken($tc_primary_blue, 10%);
    }

    .MuiSvgIcon-root {
      color: white;
      margin-right: 1rem;
    }
  }

  .controlButton {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
    }

    &:focus {
      outline: none;
      border-color: $tc_primary_blue !important;
    }
  }

  .monthYearPicker {
    border: 0;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 12px;
    font-size: 1.5rem;
    text-transform: uppercase;

    &:focus {
      outline: none;
      border-color: $tc_primary_blue;
    }
  }
}

.separatorLine {
  background-color: $tc_primary_light_gray;
  padding: 1px;
  color: white;
  border-radius: 0.8rem;
  border: none;
  height: 39.14px;
}

.dateControls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .applyButton {
    background-color: $tc_primary_blue;
    padding: 1.1rem 2rem 1.1rem 2rem;
    color: white;
    border-radius: 0.8rem;
    border: none;

    &:hover {
      background-color: darken($tc_primary_blue, 10%);
    }
  }
}

.dateControlsForm {
  display: flex;
  justify-items: center;
  padding-left: 16px;

  > .formItem {
    display: inline-block !important;
    margin-right: 0.3rem;
    margin-bottom: 0 !important;
  }
}

.viewSelect {
  padding: 1rem 2rem 1rem 1rem;
  border: 1px solid $tc_primary_blue !important;
  border-radius: 0.8rem;
  background-color: transparent;
  color: $tc_primary_blue !important;
  cursor: pointer;
  outline-color: $tc_primary_blue !important;
}

select.viewSelect {
  width: 116px;
  border: 1px solid #d8dce3;
  background: #ffffff
    url("../../../../../images/cs-assets/cs-dropdown-calendar.svg") no-repeat;
  background-size: 10px;
  background-position: right 6px center;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.leftMenu {
  position: absolute;
  left: -15px;
  width: 360px;
  height: 100vh;
  background-color: white;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.15);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out, visibility 0s linear 0.3s,
    opacity 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
}

@media (max-width: 768px) {
  .leftMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0);
    overflow-y: auto;
  }
}

.leftMenuOpen {
  transform: translateX(0);
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}

.closeIcon {
  font-size: 35px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 101;
  color: #9baab6;
}

.menuContainer {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  padding: 40px 0;
  margin-top: 30px;
  font-size: 14px;
}

.menuTitle {
  width: 100%;
  padding: 20px 20px 6px 0px;
  border-bottom: 1px solid #ccc;
  color: $tc_primary_blue;
  font-weight: 600;
  text-align: right;

  & .titleLabel {
    font-size: 1rem;
    color: #828a92;
    line-height: 1.2rem;
  }
}

.menuTitleWithLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 20px;
}

.menuSubtitle,
.menuHighlight {
  padding: 0 20px;
  line-height: 2.4rem;
  color: #828a92;
  text-align: right;
  font-style: normal;
  cursor: pointer;

  &:hover {
    color: #6c7177;
  }
}

.menuHighlight {
  border: none;
  background: none;
  margin: 0;
}

.menuSubmenuDisabled {
  color: #c8c5c5;

  &:hover {
    color: #c8c5c5;
    cursor: not-allowed;
  }
}

.filterActiveButtonMenuItem {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filterActiveButtonWrapper {
  padding: 1px 8px;
  font-weight: 600;
  color: $tc_primary_blue;
  background-color: #e4f6fe;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #e4f6fe;
  border-radius: 6px;
  gap: 4px;

  &:hover,
  &:focus {
    border: 1px solid $tc_primary_blue;
  }

  & .closeButton {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & .openFiltersButton {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
  }
}

.filtersIndicatorContainer {
  border: none;
  padding: 8px 10px;
  color: $tc_primary_blue;
  background-color: $tc_neutral_200;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 0.8rem !important;
  &:hover {
    background-color: darken($tc_neutral_200, 10%);
  }
}
