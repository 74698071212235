$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.assignmentWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  background-color: transparent !important;
  padding: 0px;
  height: auto;
  align-items: stretch;
  border-radius: 4px !important;
}

.assignmentBorder {
  width: 11px;
  border-width: 2px;
  border-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.assignmentDataWrapper {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  background-color: #f3f6f9;
  border: 1px solid #f3f6f9;
  border-left: none;

  & .assignmentTitleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 600;
    font-size: 1.2rem;
    color: #565559;
    line-height: normal;
    text-align: left;

    & .assignmentJobTime {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  & .assignmentDescriptionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: normal;
    color: #565559;
  }

  & .assignmentSplitElementBordered {
    border-top: 1px solid #dadada;
    padding: 6px 0px 0px 0px;
  }
}

.assignmentDot {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid $red;
}

.highlightedAssignment {
  background-color: #f3f6f9;
  border: 1px solid $slate_grey;
  border-radius: 4px;
}

.highlightedAssignmentDraft {
  background-color: white !important;
}

.highlightedBorder {
  width: 0;
  border: none !important;
  background-color: none !important;
}

.assignmentDescriptionWrapperFaded {
  opacity: 0.5;
}

.assignmentVerticalWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
}

.verticalTextContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.splitAssignmentVerticalWrapper {
  display: flex;
  width: 100%;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.assignmentJobHorizontalWrapper,
.splitAssignmentJobHorizontalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  gap: 0px 4px;

  & .splitAssignmentProviderName {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1rem;
  }
  & .assignmentProviderName {
    font-weight: 500;
    font-size: 1.1rem;
  }
}

.splitAssignmentJobHorizontalWrapper {
  align-items: flex-start;
  flex-wrap: nowrap;
}

.assignmentHorizontalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.assignmentHorizontalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.highlightedDataWrapper {
  border-radius: 4px;
  border: none;
  & .assignmentTitleWrapper,
  & .assignmentDescriptionWrapper {
    color: #565559 !important;
  }
}

.disabledAssignmentWrapper {
  border: none !important;
  border-radius: 4px;
}

.disabledAssignmentDataWrapper {
  opacity: 0.45;
  background-color: #f8feff !important;
}

.highlightFadedUnpublishedAssignment {
  background-color: white !important;
  opacity: 0.45;
  border: 1px solid #737373 !important;
  border-radius: 4px;
}
