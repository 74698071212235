$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$table-border-color: #d8d8d8;

:global {
  .react-table {
    table {
      width: 100%;
    }
    tr {
      td,
      th {
        padding: 6px 10px;
        line-height: 16px;
        height: 40px;
      }
    }
    tbody td {
      vertical-align: middle;
    }
    .rc-table-body {
      overflow-y: auto !important;
      overflow-x: auto !important;
    }
    .rc-column--force-wrap {
      overflow-wrap: anywhere;
    }
    .rc-column-sorters {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      .rc-column-sorter {
        margin-left: 1rem;
        i {
          display: block;
          line-height: 0.7;
          font-size: 80%;
          color: $grey;
          &.active {
            color: $tc_primary_blue;
          }
        }
      }
    }
    .rc-filter-column {
      display: flex;
      align-items: center;
      height: 100%;
      .rc-column-filter {
        line-height: 1;
        .rc-column-filter-trigger {
          cursor: pointer;
          display: block;
          padding: 5px 0.5rem;
          color: $grey;
          &:hover {
            color: $dark-grey;
            background: $baby-grey;
          }
          &.active {
            color: $tc_primary_blue;
          }
        }
      }
      .rc-column-filter-menu {
        font-weight: normal;
        cursor: default;
        &__title {
          padding: 10px 15px;
          border-bottom: 1px solid $baby-grey;
        }
        &__content {
          padding: 8px 15px;
          max-height: 300px;
          overflow-y: auto;
          &.single-select-ie {
            overflow-y: visible;
          }
        }
        &__buttons {
          padding: 8px 15px;
          border-top: 1px solid $baby-grey;
          display: flex;
          justify-content: space-between;
          .btn {
            min-width: auto;
            &.btn-link {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.table-default {
  &:global(.react-table) {
    td:first-child,
    th:first-child {
      padding-left: 20px;
    }
    td:last-child,
    th:last-child {
      padding-right: 20px;
    }

    table:last-child {
      border-bottom: 1px solid $table-border-color;
    }

    thead th {
      border-bottom: 1px solid $table-border-color;
    }

    tbody tr:not(:last-child) td {
      border-bottom: 1px solid $table-border-color;
    }
  }
}

.table-frame {
  &:global(.react-table) {
    border: 1px solid $table-border-color;
    border-radius: 6px;

    td:first-child,
    th:first-child {
      padding-left: 20px;
    }
    td:last-child,
    th:last-child {
      padding-right: 20px;
    }

    thead th {
      border-bottom: 1px solid $table-border-color;
    }
  }
}

.table-spaceBetween {
  &:global(.react-table) {
    width: 100%;
    color: #737373;
    overflow-y: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      color: #737373;
      display: block;
      max-height: 550px;
      border-collapse: separate !important;
      border-spacing: 0 10px !important;
      font-weight: 500;
      table-layout: fixed;
      overflow-y: auto;
    }

    thead {
      height: 50px;
      color: #565559;
      z-index: 2;
      position: sticky;
      top: 0px;
      background-color: white;

      tr {
        font-size: 11px;
        font-weight: 600;
        th {
          position: relative;
          padding: 16px 8px !important;
          text-align: left;
          color: #000;
        }
        th:not(:last-child)::after {
          content: "";
          position: absolute;
          top: 8px;
          bottom: 8px;
          right: 0;
          width: 1px;
          background-color: #dadada;
        }
      }
    }

    tbody {
      margin-top: 10px;
      tr {
        height: 40px;
        font-size: 13px;
        font-weight: 500;
        td {
          border-right: 1px solid #dadada;
        }
      }
    }

    td {
      padding: 5px !important;
      background-color: #f8f8f8;
      text-align: left;
      word-wrap: break-word;
      width: calc(100vw / 5);
      min-width: 100px;
      border-top: 0.5px solid #dadada;
      border-bottom: 0.5px solid #dadada;
      border-right: none;
      border-left: none;
    }

    th {
      padding: 5px !important;
      background-color: white;
      text-align: left;
      word-wrap: break-word;
      width: calc(100vw / 5);
      min-width: 100px;
      border-top: 0.5px solid #dadada;
      border-bottom: 0.5px solid #dadada;
      border-right: none;
      border-left: none;
      font-weight: 600;
    }

    th:first-child,
    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-left: 0.5px solid #dadada;
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: 0.5px solid #dadada;
    }
  }
}

.table-noBorder {
  &:global(.react-table) {
    td:first-child,
    th:first-child {
      padding-left: 0;
    }
    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }
}
