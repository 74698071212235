$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.container {
  padding: 12px 8px;
  border-bottom: 1px solid #dadada;
  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .errorBorder {
      border: 1px solid #f44336;
      outline: #f44336;
    }

    & .errorMessage {
      font-size: 1.2rem;
      color: #f44336;
    }
  }

  & .authorNameContainer {
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #565559;
    font-weight: 500;

    & .nameTitle {
      font-weight: 600;
    }
  }

  & .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .checkboxContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      color: #565559;
      background: none;
      padding: 0;
      margin: 0;
      border: none;
      cursor: pointer;

      & .checkIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border: 2px solid $tc_primary_blue;
        border-radius: 4px;
        color: $tc_primary_blue;

        & .checkIcon {
          font-size: 18px;
          stroke: 2px;
        }
      }
    }

    & .textArea {
      width: 100%;
      font-size: 1.3rem;
      resize: none;
      border-radius: 4px;
      border-color: #dadada;
      padding: 8px;
      color: #565559;
    }

    & .contentWrapper {
      padding: 8px 0px;
      & .content {
        color: #565559;
        font-size: 1.3rem;
        padding: 0;
        margin: 0;
      }
    }

    & .buttonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      & .textButton {
        background-color: transparent !important;
        color: $tc_primary_blue;
        padding: 0.8rem 1.2rem !important;
        border-radius: 0.5rem !important;
        font-size: 1.3rem !important;
        line-height: 1 !important;
        border: 0 !important;
        font-weight: 600 !important;
      }
    }
  }
}
