$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.closeIcon {
  font-size: 35px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 101;
  color: #9baab6;
}

.submenuContainer {
  width: 100%;
  height: 100%;
}

.submenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.submenuTitle {
  padding: 10px;

  h3 {
    color: #57565a;
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.6rem;
    padding: auto;
    letter-spacing: 0.5px;
  }

  p {
    color: #57565a;
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: auto;
    letter-spacing: 0.5px;
  }
}

.submenuContent {
  padding: 10px;
}

.customButton {
  padding: 0.8rem 1.2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.3rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.saveButton {
  background-color: #18618e !important;
  border-color: #18618e !important;
}
