$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.dailyArchiveModal {
  :global(.modal-dialog .modal-body) {
    max-height: 600px;
  }
}

.thinCard:global {
  &.card {
    padding: 0;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .card-header {
      height: 30px;
      text-transform: uppercase;
      border-bottom: 1px solid #e6e6e6;
      background-color: #e7f5fd;
      div {
        line-height: 1;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}

.label {
  margin-right: 1em;
}

.notice {
  color: #e25a4f;
}

.onlyForPrint {
  display: none;
}

@media print {
  .onlyForPrint {
    display: block;
    padding: 30px 15px;
    border-bottom: 0;
    background: inherit;
    font-family: inherit;
    align-items: center;
  }

  :global {
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(51, 51, 51, 0.125);
      border-radius: 0.25rem;
    }
    .card-header {
      height: 30px;
      text-transform: uppercase;
      border-bottom: 1px solid #e6e6e6;
      background-color: #e7f5fd;
    }
    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
    }
    .cardTitle {
      flex-grow: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #54575a;
      min-width: 0px;
    }
    .pl-1, .px-1 {
      padding-left: 0.25rem !important;
    }
    .pr-1, .px-1 {
      padding-right: 0.25rem !important;
    }
    .align-items-center {
      align-items: center;
    }
    .d-flex {
      display: flex;
    }
    .font-weight-bold  {
      font-weight: 700;
    }
    .border-top {
      border-top: 1px solid #dee2e6 !important;
    }
    .mt-3 {
      margin-top: 1rem !important;
    }
    .pt-3 {
      padding-top: 1rem !important;
    }
  }
}

@page {
  size: auto;
  margin: 20mm;
}
