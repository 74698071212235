$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

$job-row-height: 80px;

.jobBlock {
  display: flex;
  border-bottom: 1px solid #a5a5a5;
  position: relative;
  overflow: visible;
}

.jobRowLodaer {
  height: $job-row-height;
  border-bottom: 1px solid #a5a5a5;
  opacity: 0.5;
}

.jobInfo {
  width: 32%;
  padding: 1.5rem 2.5rem;
  background-color: #b5e7fc;
  display: flex;
  flex-direction: column;
  position: relative;
  &.isPast {
    background-color: #f3f6f9;
    color: #a5a5a5;
    .roleLine {
      color: inherit;
    }
  }
  &.isFuture {
    background-color: #e4f6fe;
  }
  .roleLine {
    color: #565559;
    &:before {
      content: "";
      display: inline-block;
      margin-right: 0.5em;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: $green;
    }
  }
  .chatIcon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
    display: none;
    border-radius: 50%;
    &:hover {
      background-color: #eee;
    }
    i {
      line-height: 20px;
    }
  }
  &:hover {
    z-index: 1;
    .chatIcon {
      display: block;
    }
  }
}

.groupCalendarLink {
  color: black !important;
  text-decoration: underline !important;
}

.shiftBlock {
  width: 68%;
  display: flex;
  background-color: $white;
  flex-direction: column;
}

.hoverUnderline {
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }
}

.hoverLink {
  composes: hoverUnderline;
  &:hover {
    color: #058cff;
  }
}
