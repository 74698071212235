@font-face {
	font-family: "ts-icons";
	font-display: block;
	src: url("/dist/ts-icons.226f60a73fb641b8d75d5f58b62f94ad.eot?#iefix") format("embedded-opentype"),
url("/dist/ts-icons.a6b22b0d6a7b6ccd84cf2a88dd154c4b.woff") format("woff"),
url("/dist/ts-icons.78534125c181c8616e3e462169eeaefa.woff2") format("woff2"),
url("/dist/ts-icons.e813109be61171934d223add4098d891.ttf") format("truetype"),
url("/dist/ts-icons.b02e0f6254d7d2c3f54533f4950e65aa.svg#ts-icons") format("svg");
}

.ts-icon {
	line-height: 1;
}

.ts-icon:before {
	font-family: ts-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ts-icon-arrow-down-circle:before {
	content: "\f101";
}
.ts-icon-arrow-left-right:before {
	content: "\f102";
}
.ts-icon-arrow-left:before {
	content: "\f103";
}
.ts-icon-arrow-right:before {
	content: "\f104";
}
.ts-icon-arrow-up-circle:before {
	content: "\f105";
}
.ts-icon-calendar4:before {
	content: "\f106";
}
.ts-icon-caret-down-fill:before {
	content: "\f107";
}
.ts-icon-caret-up-fill:before {
	content: "\f108";
}
.ts-icon-check-circle:before {
	content: "\f109";
}
.ts-icon-check:before {
	content: "\f10a";
}
.ts-icon-chevron-down:before {
	content: "\f10b";
}
.ts-icon-chevron-right:before {
	content: "\f10c";
}
.ts-icon-chevron-up:before {
	content: "\f10d";
}
.ts-icon-circle-fill:before {
	content: "\f10e";
}
.ts-icon-clock:before {
	content: "\f10f";
}
.ts-icon-close:before {
	content: "\f110";
}
.ts-icon-dot:before {
	content: "\f111";
}
.ts-icon-exclamation-circle:before {
	content: "\f112";
}
.ts-icon-filter:before {
	content: "\f113";
}
.ts-icon-funnel-fill:before {
	content: "\f114";
}
.ts-icon-funnel:before {
	content: "\f115";
}
.ts-icon-gear:before {
	content: "\f116";
}
.ts-icon-pencil:before {
	content: "\f117";
}
.ts-icon-person:before {
	content: "\f118";
}
.ts-icon-plus:before {
	content: "\f119";
}
.ts-icon-printer:before {
	content: "\f11a";
}
.ts-icon-question-circle-fill:before {
	content: "\f11b";
}
.ts-icon-search:before {
	content: "\f11c";
}
.ts-icon-sort-down:before {
	content: "\f11d";
}
.ts-icon-sort-up:before {
	content: "\f11e";
}
.ts-icon-three-dots:before {
	content: "\f11f";
}
