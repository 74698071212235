$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.departments {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 34px;
  border: 1px solid #d8dce3;
  border-radius: 6px;
  cursor: pointer;
  i {
    font-size: 14px;
    color: #d1d1d1;
    line-height: inherit;
  }
}

.departmentsDropdown {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  min-width: 200px;

  .searchContainer {
    height: 40px;
    display: flex;
    align-items: center;
    .searchBox {
      :global(input.form-control) {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $light-grey;
        font-size: 14px;
        box-shadow: none;
        &:focus {
          box-shadow: none;
          border-bottom: 1px solid $grey;
        }
      }
    }
    .searchButton i {
      color: #9baab6;
    }
  }

  .departmentList {
    width: 100%;
    overflow-y: auto;
    .departmentItem {
      height: 40px;
      line-height: 40px;
      // Override checkbox margin bottom style
      &:not(:last-child) {
        margin-bottom: 0;
      }
      input[type="checkbox"] {
        & + div label {
          font-size: 14px;
        }
        &:checked + div label {
          font-weight: bold;
        }
      }
    }
  }
}
