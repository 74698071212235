$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.calendarActions {
  width: 100%;
  text-align: right;
}

.calendarView {
  overflow: scroll;

  .calendar {
    margin-top: 1em;
    margin-bottom: 2em;
    width: 100%;
    min-width: 960px;
    table-layout: fixed;
    font-size: 14px;

    .weekHeader {
      .emptyDay {
        border-right: solid 1px var(--neutral-500);
        width: 100px;
      }

      .headerDay {
        border-right: solid 1px var(--neutral-500);
        padding: 3px 10px;
        border-top: solid 1px var(--neutral-500);
        border-bottom: solid 1px var(--neutral-500);
        background-color: var(--neutral-150);
        color: var(--neutral-900);

        .headerWrapper {
          display: flex;
          align-items: center;
          flex-direction: row;

          .headerText {
            flex: 1 1 auto;
          }
        }
      }
    }

    .week {
      .weekLabelCell {
        text-align: right;
        color: var(--neutral-900);
        vertical-align: top;
        padding-top: 5px;
        padding-right: 0px;

        .weekLabelWrapper {
          background: var(--neutral-200);
          padding: 3px 10px;
          display: inline-flex;
          border-radius: 6px 0 0 6px;

          .weekLabelText {
            margin-right: 2px;
          }
        }
      }

      .day {
        padding: 8px 10px;
        vertical-align: top;
        border-left: solid 1px var(--neutral-500);
        border-bottom: solid 1px var(--neutral-500);
        height: 120px;

        &:hover {
          box-shadow: inset 0 0 0 3px var(--cool-neutral-400);
          cursor: pointer;
        }

        &:last-child {
          border-right: solid 1px var(--neutral-500);
        }

        &.selectedDay {
          background: var(--cool-neutral-300);
        }

        .job {
          background: var(--cool-neutral-400);
          color: var(--secondary-900);
          padding: 2px 5px;
          margin-bottom: 4px;
          border-radius: 3px;
          font-weight: 600;
          font-size: 11px;

          .jobTime {
            font-weight: 400;
          }
        }
      }
    }
  }
}
