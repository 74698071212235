$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

:root {
  /* Neutrals */
  --neutral-100: #ffffff;
  --neutral-100-rgb: 255, 255, 255;
  --neutral-150: #f8f8f8;
  --neutral-200: #f4f4f4;
  --neutral-300: #e6e6e6;
  --neutral-400: #dadada;
  --neutral-500: #bbbbbb;
  --neutral-500-rgb: 187, 187, 187;
  --neutral-600: #a5a5a5;
  --neutral-700: #979797;
  --neutral-800: #737373;
  --neutral-900: #565559;
  --neutral-950: #4d4d4d;
  --neutral-1000: #35333c;

  /* Cool Neutrals */
  --cool-neutral-100: #f7fdff;
  --cool-neutral-200: #f3f6f9;
  --cool-neutral-300: #e4f6fe;
  --cool-neutral-350: #dfefeb;
  --cool-neutral-400: #b5e7fc;
  --cool-neutral-450: #dce9f4;
  --cool-neutral-500: #c3dbe5;
  --cool-neutral-700: #9baab6;
  --cool-neutral-800: #99a4a5;
  --cool-neutral-900: #6f7982;

  /* Primary */
  --primary-100: #ff8d87;
  --primary-300: #df655f;
  --primary-500: #f22f29;
  --primary-700: #d92a25;
  --primary-900: #af241e;

  /* Scheduling Primary */
  --scheduling-primary-100: #42a7c6;
  --scheduling-primary-300: #5492a8;
  --scheduling-primary-500: #48788d;
  --scheduling-primary-700: #3d5f72;

  /* Secondary */
  --secondary-100: #b2dcff;
  --secondary-200: #80c4ff;
  --secondary-300: #4dadff;
  --secondary-500: #058cff;
  --secondary-600: #0070d0;
  --secondary-700: #3c82cd;
  --secondary-900: #2d6199;

  /* Message Status */
  --message-status-read: #418a46;
  --message-status-sent: #4696d8;
  --message-status-delivered: #489eb4;

  /* Patient Network */
  --patient-network-100: #f6fdff;
  --patient-network-200: #f0f5f6;
  --patient-network-300: #e1ebee;
  --patient-network-500: #dfefeb;
  --patient-network-600: #759cbb;
  --patient-network-700: #4a657b;
  --patient-network-800: #4078a5;

  /* Success */
  --success-100: #99ffc7;
  --success-300: #57d991;
  --success-500: #04a74d;
  --success-700: #037335;
  --success-900: #024d23;

  /* Errors */
  --errors-50: #ffebea;
  --errors-100: #ffc5c2;
  --errors-300: #ff7d76;
  --errors-500: #db524d;
  --errors-700: #a83f3b;
  --errors-900: #752c29;

  /* Teams */
  --teams-100: #c2dabf;
  --teams-900: #597752;
}
