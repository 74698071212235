$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.block {
  background-color: $white;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  user-select: none;
  cursor: pointer;
}

.blockBuffer {
  cursor: default;
  border-style: dashed;
  background-color: #e8e9ec;
  opacity: 0.6;
}

.blockUnassigned {
  background-color: #e8e9ec;
  &:hover {
    border-color: #3080df;
  }
}

.blockNormal {
  display: flex;
  flex-direction: column;
  &:hover {
    border-color: #3080df !important;
    .blockHeader i {
      display: block;
    }
    .blockContent {
      border-radius: 4px;
    }
  }
}

.quickAssignmentDisabled {
  background-color: gray;
  opacity: 0.4;
  cursor: not-allowed;
}

.highlightDisabled {
  background-color: gray;
  opacity: 0.4;
}

.dateUpdateForm {
  padding: 20px;
  height: 100px;
}

.blockContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5em 0 0.5em 0.6em;
  border-left-color: transparent;
  border-left-width: 4px;
  border-left-style: solid;
  overflow-y: auto;
  overflow-x: hidden;
  @include customScrollbar();

  .rotationAbbr {
    white-space: nowrap;
  }
  .requestItem {
    white-space: nowrap;
    line-height: 1;
  }
}

.rotationsTable {
  th.rotationName {
    width: 150px;
  }
  td.rotationName {
    max-width: 300px;
  }
  // These 2 tallyName classes support tallyName auto resizing
  // based on width of rotation name column
  th.tallyName {
    width: 200px;
  }
  td.tallyName {
    max-width: 1px;
  }

  td.tallyTarget,
  td.tallyCredit {
    min-width: 100px;
  }
  .rotationAction {
    max-width: 100px;
  }
}

.rotationInfoItem {
  display: flex;
  margin-bottom: 0.5rem;

  .rotationInfoItemName {
    width: 80px;
  }
}
